import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
        path: '/',
        name: 'home',
        component: () => import('./views/Form.vue')
    },
    {
        path: '/2024magiczneMikolajki',
        name: '2024magiczneMikolajki',
        component: () => import('./views/2024magiczneMikolajki.vue')
    },
    {
      path: '/konkursDzienDziecka',
      name: 'konkursDzienDziecka',
      component: () => import('./views/2024konkursDzienDziecka.vue')
    },
    {
      path: '/aktywne-wakacje',
      name: 'aktywne-wakacje',
      component: () => import('./views/2024aktywneWakacje.vue')
    },
    {
      path: '/aktywne-wakacje-edycja-specjalna',
      name: 'aktywne-wakacje-edycja-specjalna',
      component: () => import('./views/2024aktywneWakacjeSpecjalne.vue')
    },
    {
      path: '/AMG-Experience',
      name: 'AMG-Experience',
      component: () => import('./views/AMG-Experience.vue')
    },
    {
      path: '/G-Class-Experience',
      name: 'G-Class-Experience',
      component: () => import('./views/G-Class-Experience.vue')
    },
    {
      path: '/GOLF-CUP-2024-uczestnik',
      name: 'GOLF-CUP-2024-uczestnik',
      component: () => import('./views/GOLF-CUP-2024-uczestnik.vue')
    },
    {
      path: '/GOLF-CUP-2024-zawodnik',
      name: 'GOLF-CUP-2024-zawodnik',
      component: () => import('./views/GOLF-CUP-2024-zawodnik.vue')
    },
    {
      path: '/tech-experience',
      name: '2024konkursTechExperience',
      component: () => import('./views/2024konkursTechExperience.vue')
    },
    {
      path: '/jesienna-przygoda',
      name: '2024konkursJesiennaPrzygoda',
      component: () => import('./views/2024konkursJesiennaPrzygoda.vue')
    },
    {
        path: '*',
        name: '404',
        component: () => import('./views/404.vue')
    }
  ]
})
